<template>
    <div class="page">
        <!-- <div class="title">任务</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in task" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">客户</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in client" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">合同</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in order" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>

        <div class="title">员工</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in staff" :key="index"> 
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">产品</div>
        <div class="menu">
            <div class="meun-item" v-for="(item, index) in goods" :key="index">
            <router-link :to="item.link">
                <img :src="item.url" :alt="item.text" />
                <div class="item-text">{{ item.text }}</div>
            </router-link>
            </div>
        </div>
        <div class="title">目标</div>
        <div class="menu">
            <div class="meun-item" v-for="(item, index) in target" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text" />
                    <div class="item-text">{{ item.text }}</div>
                </router-link>
            </div>
        </div>
        <div class="title">线索</div>
        <div class="menu">
            <div class="meun-item" v-for="(item, index) in clue" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text" />
                    <div class="item-text">{{ item.text }}</div>
                </router-link>
            </div>
        </div>
         <div class="title">工人</div>
        <div class="menu">
            <div class="meun-item" v-for="(item, index) in temp" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text" />
                    <div class="item-text">{{ item.text }}</div>
                </router-link>
            </div>
        </div>
        
        <div class="title">服务人员</div>
        <div class="menu">
            <div class="meun-item" v-for="(item, index) in worker" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text" />
                    <div class="item-text">{{ item.text }}</div>
                </router-link>
            </div>
        </div>
        <div class="title">统计</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in datas" :key="index">
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div>
        <div class="title">设置</div>
        <div class="menu">
            <div class="meun-item" v-for="(item,index) in set" :key="index"> 
                <router-link :to="item.link">
                    <img :src="item.url" :alt="item.text">
                    <div class="item-text">{{item.text}}</div>
                </router-link>
            </div>
        </div> -->
        <template v-for="(item,index) in data">
            <div v-if="item.url!='home'" :key="index">
                <div class="title">{{item.title}}</div>
                <div class="menu">
                    <template v-for="(o,i) in item.sub">
                        <div class="meun-item" v-if="o.link" :key="i+'-'+index">
                            <router-link :to="o.link">
                                <img :src="o.icon" :alt="o.title">
                                <div class="item-text">{{o.title}}</div>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <div style="height: 100px"></div>
    </div>
</template>
<script>
import { requestXml } from "../../assets/js/request";
export default {
    data() {
        return {
            task: [
                {"text":"全部任务","url":require("@/assets/imgs/wap/task1.png"),"link":"task/taskAllList"},
                {"text":"我的新建","url":require("@/assets/imgs/wap/task1.png"),"link":"task/taskList"},
                {"text":"我的待办","url":require("@/assets/imgs/wap/task2.png"),"link":"task/taskList2"},
                {"text":"新建任务","url":require("@/assets/imgs/wap/task3.png"),"link":"task/taskAdd"},
            ],
            client: [
                // {"text":"添加客户","url":require("@/assets/imgs/wap/client1.png"),"link":"client/clientList"},
                {"text":"全部客户","url":require("@/assets/imgs/wap/client2.png"),"link":"client/clientList"},
                {"text":"我的客户","url":require("@/assets/imgs/wap/client3.png"),"link":"client/clientMy"},
                {"text":"全部联系人","url":require("@/assets/imgs/wap/client2.png"),"link":"client/linkmanAll"},
                {"text":"我的联系人","url":require("@/assets/imgs/wap/client3.png"),"link":"client/linkmanMy"},
            ],
            order:[
                {"text":"全部合同","url":require("@/assets/imgs/wap/order1.png"),"link":"order/orderAllList"},
                {"text":"我的合同","url":require("@/assets/imgs/wap/order1.png"),"link":"order/orderList"},
                {"text":"新建合同","url":require("@/assets/imgs/wap/order2.png"),"link":"order/orderAdd"},
                {"text":"全部回款列表","url":require("@/assets/imgs/wap/order3.png"),"link":"order/paymentListBranch"},
                {"text":"回款列表","url":require("@/assets/imgs/wap/order3.png"),"link":"order/paymentList"},
                {"text":"退款列表","url":require("@/assets/imgs/wap/order3.png"),"link":"order/refundList"},
                {"text":"全部发票","url":require("@/assets/imgs/wap/order1.png"),"link":"order/invoiceList"},
                {"text":"我领取的发票","url":require("@/assets/imgs/wap/order1.png"),"link":"order/invoiceSignList"},
                {"text":"我创建的发票","url":require("@/assets/imgs/wap/order2.png"),"link":"order/invoiceCreaterList"},
                {"text":"添加发票","url":require("@/assets/imgs/wap/order3.png"),"link":"order/invoiceAdd"},
            ],
            datas:[
                {"text":"经营日报","url":require("@/assets/imgs/wap/datas1.png"),"link":"datas/daily"},
                {"text":"客户分析","url":require("@/assets/imgs/wap/datas2.png"),"link":"datas/client"},
                {"text":"合同分析","url":require("@/assets/imgs/wap/datas3.png"),"link":"datas/order"},
                {"text":"目标分析","url":require("@/assets/imgs/wap/datas3.png"),"link":"datas/goal"},
            ],
            staff: [{"text":"员工列表","url":require("@/assets/imgs/wap/staff.png"),"link":"set/staffList"}],

            goods: [
                {text: "产品列表",url: require("@/assets/imgs/wap/task1.png"),link: "goods/goodsList",},
                {text: "产品分类",url: require("@/assets/imgs/wap/task2.png"),link: "goods/goodsType",},
                {text: "产品新增",url: require("@/assets/imgs/wap/task3.png"),link: "goods/goodsAdd",},
            ],
            target: [
                {text: "我的目标",url: require("@/assets/imgs/wap/datas3.png"),link: "target/my"},
                {text: "团队目标",url: require("@/assets/imgs/wap/order1.png"),link: "target/group"},
            ],
            clue:[
                {text: "线索列表",url: require("@/assets/imgs/wap/staff.png"),link: "clue/clueList"},
            ],
            temp:[
                {text: "工人列表",url: require("@/assets/imgs/wap/staff.png"),link: "temp/tempList"},
                {text: "工人新增",url: require("@/assets/imgs/wap/task3.png"),link: "temp/tempAdd"},
                {text: "工人技能",url: require("@/assets/imgs/wap/task2.png"),link: "temp/tempType"},
                {text: "工人考勤",url: require("@/assets/imgs/wap/task1.png"),link: "temp/tempCheck"},
                {text: "项目列表",url: require("@/assets/imgs/wap/staff.png"),link: "item/itemList"},
                {text: "项目新增",url: require("@/assets/imgs/wap/task3.png"),link: "item/itemAdd"},
                {text: "工人设置",url: require("@/assets/imgs/wap/tabC4.png"),link: "item/itemSet"},
            ],
            worker:[
                {text: "服务人员列表",url: require("@/assets/imgs/wap/staff.png"),link: "worker/workerList"},
                {text: "我的服务人员",url: require("@/assets/imgs/wap/staff.png"),link: "worker/workersMy"},
                {text: "服务人员新增",url: require("@/assets/imgs/wap/task3.png"),link: "worker/workerAdd"},
                {text: "工作点管理",url: require("@/assets/imgs/wap/staff.png"),link: "worker/operatList"},
                {text: "我的工作点",url: require("@/assets/imgs/wap/staff.png"),link: "worker/operatMy"},
                {text: "工作点新增",url: require("@/assets/imgs/wap/task3.png"),link: "worker/operatAdd"},
                {text: "工作点设置",url: require("@/assets/imgs/wap/tabC4.png"),link: "worker/workerSet"},
                {text: "工装类型",url: require("@/assets/imgs/wap/task2.png"),link: "worker/clotype"},
                {text: "服务人员薪资",url: require("@/assets/imgs/wap/order3.png"),link: "worker/workerPay"},
                // {text: "薪资录入",url: require("@/assets/imgs/wap/task3.png"),link: "worker/workerPayAdd"},
            ],
            set:[
                // {"text":"基础设置","url":require("@/assets/imgs/wap/tabC4.png"),"link":"set/index"},
                {text: "轮播图设置",url: require("@/assets/imgs/wap/task3.png"),link: "miniApp/carouselList"},
                {text: "小程序设置",url: require("@/assets/imgs/wap/staff.png"),link: "miniApp/miniAppSet"},
            ],
            data:[]
        };
    },
    mounted(){
        this.getList()
    },
    methods:{
        // 获取展示列表数据
        getList() {
            requestXml("/scrm/index/getMenuMobile","GET",(res) => {
                this.data=res
                this.data.forEach(element => {
                    if(element.url!='home'){
                        var arr=this.[element.url]
                        element.sub.forEach(item => {
                            for(var i=0;i<arr.length;i++){
                                if(arr[i].link.split('/')[1]==item.url){
                                    item.link=arr[i].link
                                    item.icon=arr[i].url
                                    item.text=arr[i].text
                                }
                            }
                        });
                    }
                });
            });
        },
    }
};
</script>
<style>
.menu {
    background: #ffffff;
    text-align: left;
}
.meun-item {
    display: inline-block;
    width: 33.333%;
    text-align: center;
    height: 74px;
    font-size: 13px;
    color: #202020;
}

.meun-item img {
    display: inline-block;
    width: 20px;
    height: 23px;
    margin: 15px 0 5px;
    vertical-align: middle;
}
.title {
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #999;
    text-align: left;
    padding: 0 15px;
}
</style>
